<template>
  <div>
    <div class="_BoxHeader">
      <span>职能管理</span>
    </div>
    <div class="PageContain">
      <div class="headerTitle">
        <span class="_Title">信息列表</span>
        <div class="search-div">
          <span>职能名称：</span
          ><el-input v-model="inpName" placeholder="请输入文件名"></el-input>
        </div>
        <div class="search-div">
          <span>职能编号：</span
          ><el-input v-model="inpNo" placeholder="请输入文件编号"></el-input>
        </div>
        <el-button
          type="success"
          icon="el-icon-search"
          style="margin-left: 10px"
          @click="getFunList(false)"
          >搜索</el-button
        >
      </div>
      <div class="tow-box">
        <div>
          <p>文件列表下与文件相关的职能名称</p>
          <el-table
            :data="functionList"
            style="width: 100%"
            :header-cell-style="{ background: '#E8EBF4', color: '#5C5C5C' }"
            stripe
            :max-height="elementHeight"
            id="table"
          >
            <el-table-column prop="functionName" label="职能名称" width="180">
            </el-table-column>
            <el-table-column prop="functionNo" label="职能编号" width="180">
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <i
                  class="el-icon-delete"
                  @click="deleteItem(scope.row, scope.$index)"
                ></i>
                <i class="el-icon-edit" @click="editItem(scope.row)"></i>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div>
          <p>{{ isAdd ? '添加职能' : '修改职能' }}</p>
          <div class="_Inp">
            <span>职能名称：</span>
            <el-input placeholder="请输入" v-model="functionNanme" />
          </div>
          <div class="_Inp">
            <span>职能编号：</span>
            <el-input placeholder="请输入" v-model="functionNo" />
          </div>
          <el-button type="primary" @click="addFun">确定</el-button>
          <el-button v-if="!isAdd" @click="cancle">取消</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, post, put, deleteMethod } from '../../api/http'
export default {
  data() {
    return {
      elementHeight: 0,
      functionNanme: '',
      functionNo: '',
      functionList: [],
      isAdd: true,
      rowItem: {},
      inpNo: '',
      inpName: '',
    }
  },
  mounted() {
    this.getFunList(true)
  },
  methods: {
    getElementHeight() {
      this.$nextTick(() => {
        this.elementHeight =
          window.innerHeight -
          (document.getElementById('table').offsetTop + 60 + 52)
      })
    },
    getFunList(showHeight) {
      var url =
        this.inpNo || this.inpName
          ? '/api/Function/GetByFunction?FunctionNo=' +
            this.inpNo +
            '&FunctionName=' +
            this.inpName
          : '/api/Function/GetList'

      get(url).then((resp) => {
        if (resp.code == 200) {
          this.functionList = resp.data
          if (showHeight) this.getElementHeight()
        }
      })
    },
    addFun() {
      if (this.functionNanme == '' || this.functionNo == '') return
      var data = {
        id: this.isAdd ? 0 : this.rowItem.id,
        functionNo: this.functionNo,
        functionName: this.functionNanme,
        isDelete: 0,
        saveTime: this.isAdd ? new Date().toISOString : this.rowItem.saveTime,
        saveUserId: JSON.parse(sessionStorage.getItem('userInfo')).id + '',
      }
      var methods = this.isAdd ? post : put
      methods('/api/Function', data)
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: 'success',
              message: this.isAdd ? '添加成功' : '修改成功',
              duration: 1000,
            })
            this.cancle()
            this.getFunList(false)
          } else {
            this.$message.error(resp.message)
          }
        })
        .catch(() => {
          this.$message.error(this.isAdd ? '添加失败' : '修改失败')
        })
    },
    deleteItem(row, index) {
      this.$confirm('您确认要删除' + row.functionName + '?')
        .then(() => {
          deleteMethod('/api/Function?id=' + row.id)
            .then((resp) => {
              if (resp.code == 200) {
                this.$message({
                  type: 'success',
                  message: '删除成功',
                  duration: 1000,
                })
                this.functionList.splice(index, 1)
              } else {
                this.$message.error(resp.message)
              }
            })
            .catch(() => {
              this.$message.error('删除失败')
            })
        })
        .catch(() => {})
    },
    editItem(row) {
      this.isAdd = false
      this.rowItem = row
      this.functionNanme = row.functionName
      this.functionNo = row.functionNo
    },
    cancle() {
      this.isAdd = true
      this.functionNo = ''
      this.functionNanme = ''
    },
  },
}
</script>

<style lang="less" scoped>
._Inp {
  width: 240px;
  /deep/ .el-input__inner {
    background-color: #edf0f7 !important;
  }
}
.headerTitle {
  display: flex;
  align-items: center;
  margin-top: 10px;
  & .search-div {
    width: 250px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    > span {
      width: 80px;
      text-align: end;
    }
  }
}

.tow-box {
  display: flex;
  justify-content: center;
  padding-left: 7px;
  > div {
    width: 45%;
    margin-top: 10px;
    padding-left: 25px;
    > p {
      border-left: 5px solid #5a8bff;
      padding-left: 5px;
      margin: 5px 0 15px 0;
    }
    .el-button:nth-of-type(1) {
      margin-left: 70px;
      margin-top: 10px;
    }
    .el-button:nth-of-type(2) {
      margin-left: 10px;
      margin-top: 10px;
    }
  }
  /deep/ .el-table__row > td:nth-of-type(1) {
    background-color: #f6f6f686;
  }
  /deep/ .el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border-bottom: transparent !important;
  }
  .el-table {
    border: 1px solid #c2c2c2;
    & i {
      border-radius: 50%;
      border: 1px solid silver;
      padding: 5px;
      margin: 0 5px;
    }
    & i:nth-of-type(1) {
      background-color: #fef9f9;
      color: red;
      border: 1px solid #d3b2b2;
    }
    & i:nth-of-type(2) {
      background-color: #eff3fa;
      color: #5a8bff;
      border: 1px solid #b2c7d3;
    }
  }
  .el-table--fit {
    border-right: 1px solid #c2c2c2;
    border-bottom: 1px solid #c2c2c2;
  }
}
</style>
